<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment_ind</md-icon>
          </div>
          <h4 class="title">Tabela de Servidores Públicos</h4>
          <h5 class="title">
            Selecione os campos e adicione os SIAPEs no campo para busca.
          </h5>
        </md-card-header>
        <md-card-content>
          <p></p>
          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="padding-top: 10px;"
          >
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                  name="Nome das Colunas"
                  :rules="{
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <template>
                    <br />
                    <label class="text-left"
                      >Selecione a ordem das colunas que deseja exportar ao
                      menos 2 Colunas.
                    </label>

                    <treeselect
                      v-model="nomeColunas"
                      :multiple="true"
                      :options="opcoesNomesColunas"
                      :clear-on-select="true"
                    />
                  </template>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
                <ValidationProvider
                  name="siape"
                  :rules="{
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <br />
                  <md-field>
                    <label class="text-left"
                      >Digite os siapes ou copie e cole para consultar,
                      coloque-os siapes separados por ' , ' (virgula).
                    </label>
                    <md-textarea v-model="siape" md-autogrow></md-textarea>
                  </md-field>
                  <!--<md-chips
                    class="md-default chip_border"
                    v-model="siapes"
                    md-placeholder="Digite os siapes ou copie e cole"
                    :md-auto-insert="true"
                  >
                    <label>Siapes</label>
                  </md-chips>-->
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
                <div class="md-size-15">
                  <md-button class="md-raised md-success mt-4" type="submit">
                    <md-icon>search</md-icon>
                    Buscar
                  </md-button>
                  &nbsp;&nbsp;
                  <md-button
                    class="md-raised mt-4"
                    type="reset"
                    @click="reset()"
                  >
                    limpar
                  </md-button>
                </div>
              </form>
            </ValidationObserver>
          </div>

          <br />
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <span class="space-tool-bar"></span>
              <span class="space-tool-bar"></span>
              <span class="space-tool-bar"></span>
              <span class="space-tool-bar"></span>
              <span class="space-tool-bar"></span>
              <div class="md-size-15">
                <md-button
                  class="md-raised md-success mt-4"
                  @click="downloadExportCall()"
                >
                  <md-icon>cloud_download</md-icon>
                  Exportar
                </md-button>
              </div>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                v-for="(value, name, index) in item"
                :key="index"
                :mdLabel="name"
                >{{ value }}</md-table-cell
              >
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <!--<tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.email"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>-->
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
//import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
//import { siapeServidorMask } from "@/pages/Dashboard/js/siapeMask";
import { downloadExport, instance } from "../js/file-upload.download.service";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import * as axios from "axios";
import { getLocalToken } from "@/modules/auth/storage";

const axiosInstance = instance;

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    }
  },
  components: {
    Pagination,
    Treeselect
  },
  computed: {
    ...mapState("auth", ["user_profile"]),

    user_profile_state: state => state.user_profile,

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.tableData.length > 0) {
        return result;
      } else {
        return [];
      }
    },
    to() {
      if (this.totalRegistros === 0) {
        return 0;
      } else {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if (this.totalRegistros === 0) {
        return -1;
      } else {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    total() {
      return this.totalRegistros;
    },
    reload: function() {
      this.getEntidadeAll();
      this.getServidorPublicoPagination();
      return null;
    }
  },
  data() {
    return {
      mensagem: "",
      idPapelSelected: this.id,
      isFetching: true,
      papel: "",
      currentSort: "nomeCompleto",
      currentSortOrder: "desc",
      totalRegistros: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200, 500, 1000],
        total: 0
      },
      siape: [],
      tableData: [],
      nomeColunas: null,
      opcoesNomesColunas: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.getServidorPublicoNomeColunasAll();
    const token = getLocalToken();
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  methods: {
    ...mapActions("servidor_publico", [
      "ActionGetServidorPublicoNomeColunasPagination",
      "ActionGetServidorPublicoNomeColunasAll"
    ]),
    siapeServidorMaskCall(value) {
      value = value !== "" && value !== null ? siapeServidorMask(value) : null;
      return value;
    },
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    callCpfMask(value) {
      return cpfMask(value.toString());
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    reset() {
      this.tableData = [];
      this.totalRegistros = 0;
      this.siape = [];
      this.nomeColunas = null;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    downloadExportCall() {
      let siape = this.siape;
      let nomeColunas = this.formatJsonNomeColunas(this.nomeColunas);
      downloadExport(axiosInstance, this.papel, { nomeColunas, siape });
    },
    async getServidorPublicoNomeColunasAll() {
      try {
        let papel = this.papel;
        await this.ActionGetServidorPublicoNomeColunasAll({
          papel
        }).then(response => {
          if (response.status === 200) {
            //console.log(response);
            if (response.body.mensagem && response.body.mensagem !== null) {
              this.opcoesNomesColunas = [];
              this.nomeColunas = null;
            } else {
              response.body.colunas_servidor.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              });
              this.opcoesNomesColunas = response.body.colunas_servidor;
            }
          }
          this.isFetching = false;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    formatJsonNomeColunas(colunas) {
      let jsonNomeColunas = [];

      colunas.forEach(element1 => {
        this.opcoesNomesColunas.forEach(element2 => {
          let json = element2;
          if (json.id === element1) {
            let coluna = {
              nomeColuna: json.nomeColuna
            };
            jsonNomeColunas.push(Vue.util.extend({}, coluna));
          }
        });
      });
      return jsonNomeColunas;
    },
    async getServidorPublicoNomeColunasPagination() {
      try {
        let papel = this.papel;
        let siape = this.siape;
        let nomeColunas = this.formatJsonNomeColunas(this.nomeColunas);
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        await this.ActionGetServidorPublicoNomeColunasPagination({
          papel,
          siape,
          nomeColunas,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (this.mensagem.includes("não")) {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: this.mensagem,
                  showCancelButton: false,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false,
                  confirmButtonText: "Ok"
                });
                this.tableData = [];
                this.totalRegistros = 0;
              } else {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: "OPSSS!! Algo deu Errado.",
                  showCancelButton: false,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false,
                  confirmButtonText: "Ok"
                });
              }
            } else {
              this.tableData = response.body.servidor;
              this.totalRegistros = response.body.totalRegistros;
              //console.log(this.tableData, this.totalRegistros);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getServidorPublicoNomeColunasPaginationNext() {
      try {
        let papel = this.papel;
        let siape = this.siape;
        let nomeColunas = this.formatJsonNomeColunas(this.nomeColunas);
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        await this.ActionGetServidorPublicoNomeColunasPagination({
          papel,
          siape,
          nomeColunas,
          noOfRecords,
          pageIndex
        }).then(res => {
          //console.log(res);
          this.tableData = res.body.servidor;
        });
      } catch (err) {
        //console.log(err);
        this.handleError(err);
      }
    },
    async submit() {
      try {
        await this.getServidorPublicoNomeColunasPagination();
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    mergeSiape(val) {
      this.siape = val;
      return null;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    }
  },
  watch: {
    siape: {
      handler: function(val, oldVal) {
        let countSplits = 0;
        let values = [];
        if (!Array.isArray(val)) {
          values = val.split(",");
        }
        values.forEach(function() {
          values[countSplits] = values[countSplits].trim();
          countSplits++;
        });
        if (countSplits > 0) {
          this.mergeSiape(values);
        }
      },
      deep: true
    },
    pagination: {
      handler: function(val, oldVal) {
        if (this.searchQueryOrgao === "" || this.searchQueryOrgao === null) {
          this.getServidorPublicoNomeColunasPaginationNext();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: 400;
}

.footer-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.md-table-cell {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.74rem;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.switch-div {
  /*display: inline-block;*/
  float: left;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

.size35 i {
  font-size: 35px !important;
}

::v-deep .md-toolbar-section-start {
  padding-bottom: 20px;
}

::v-deep .md-card .md-card-content {
  padding: 2px 20px;
}

::v-deep .vue-treeselect__multi-value-item {
  background: #62dc67;
  color: #000000;
  font-weight: 500;
}

::v-deep .vue-treeselect__value-remove {
  color: #000000;
}
</style>
