import * as axios from "axios";
import store from "../../../store";
import BASE_URL from "@/properties.js";

export const instance = axios.create({
  baseURL: BASE_URL
});

const token = localStorage.getItem("token");
instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function upload_csv(formData) {
  return instance
    .post(`/api/v1/admin/importar_csv/upload`, formData)
    .then(x => x)
    .catch(function(error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else if (error.request) {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error);
      }
    });
}

function upload_pdf_zip(formData) {
  return instance
    .post(`/api/v1/admin/importar_pdf_zip/upload`, formData)
    .then(x => x)
    .catch(function(error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else if (error.request) {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error);
      }
    });
}

function downloadExport(axios, papel, body) {
  if (papel === "ADMIN") {
    axios({
      url: `api/v1/admin/servidorpublico/nome_colunas/export`,
      method: "POST",
      data: body,
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        let ts = new Date();
        let timestamp = ts.toISOString().split(".")[0];
        timestamp = timestamp.replace(":", "_");
        link.setAttribute("download", "export-" + timestamp + ".csv");
      }
      document.body.appendChild(link);
      link.click();
    });
  }
}

export { upload_csv, upload_pdf_zip, downloadExport };
