<template>
  <div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="showCards"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <pricing-card icon-color="icon-success">
            <h6 slot="category" class="category"></h6>
            <md-icon slot="icon">cloud_upload</md-icon>
            <h3 slot="title" class="title">IMPORTAR CSV</h3>
            <p slot="description" class="card-description">
              Importe arquivos .csv para registros de Servidor Publico ,
              Pensionista , Quota Parte ou Ficha Financeira.
            </p>
            <md-button
              slot="footer"
              class="md-success md-round"
              @click="importFile('csv')"
              >IMPORTAR CSV</md-button
            >
          </pricing-card>
        </div>
        <div class="md-layout-item">
          <pricing-card icon-color="icon-success">
            <h6 slot="category" class="category"></h6>
            <md-icon slot="icon">picture_as_pdf</md-icon>
            <h3 slot="title" class="title black-color">IMPORTAR PDFs</h3>
            <p slot="description" class="card-description">
              Importe arquivos comprimidos .zip com PDFs de resultado de
              calculos e relatórios.
            </p>
            <md-button
              slot="footer"
              class="md-success md-round"
              @click="importFile('pdf')"
              >IMPORTAR PDFs</md-button
            >
          </pricing-card>
        </div>
        <div class="md-layout-item">
          <pricing-card icon-color="icon-success">
            <h6 slot="category" class="category"></h6>
            <md-icon slot="icon">cloud_download</md-icon>
            <h3 slot="title" class="title black-color">
              EXPORTAR SERVIDORES
            </h3>
            <p slot="description" class="card-description">
              Exporte servidores escolhendo os campos necessários e filtrando
              por SIAPE e para arquivos CSV.
            </p>
            <md-button
              slot="footer"
              class="md-success md-round"
              @click="goToExportarServidores"
              >EXPORTAR CSV</md-button
            >
          </pricing-card>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="md-layout md-gutter md-alignment-center" v-if="showCSV">
          <div class="md-layout-item">
            <h5
              class="title"
              style="font-weight:350;padding-bottom: 5px;margin-bottom: 5px; margin-top: 5px;"
            >
              Escolha o arquivo CSV para importar e em seguido o tipo de
              importação.
            </h5>
            <md-button
              class="md-dense md-raised md-success"
              @click="importFile('initial')"
            >
              Voltar</md-button
            >&nbsp;&nbsp;
            <md-button
              class="md-dense md-raised md-success"
              @click="importFile('pdf')"
              >IMPORTAR PDFs</md-button
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <div class="panel panel-sm">
              <div class="panel-heading">
                <h4>Importar CSV</h4>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-100"
                style="padding-top: 10px;"
              >
                Modelo para download :
                <a
                  style="padding: 0px 10px 0px 0px; font-weight: 400;"
                  href="/files/servidor.csv"
                  download
                  >Servidor</a
                >
                <a
                  style="padding: 0px 10px 0px 0px; font-weight: 400;"
                  href="/files/pensionista.csv"
                  download
                  >Pensionista</a
                >
                &nbsp;
                <a
                  style="padding: 0px 10px 0px 0px; font-weight: 400;"
                  href="/files/quotas.csv"
                  download
                  >Quota_Parte</a
                >
                &nbsp;
                <a
                  style="padding: 0px 10px 0px 0px; font-weight: 400;"
                  href="/files/ficha_financeira.csv"
                  download
                  >Ficha_Financeira</a
                >
                &nbsp;
                <div class="label-arquivo">
                  <form
                    v-bind:id="1"
                    :key="1"
                    enctype="multipart/form-data"
                    ref="form"
                  >
                    <label for="csv_file" class="text-left"
                      >Arquivo CSV para importar
                    </label>

                    <div class="">
                      <input
                        type="file"
                        id="csv_file"
                        name="csv_file"
                        class="form-control"
                        @change="loadCSV($event)"
                      />
                    </div>
                  </form>
                  <!--  <label for="csv_file" class="text-right"
                      >Arquivo CSV para importar
                    </label>
                    <div class="">
                      <input
                        type="file"
                        id="csv_file"
                        name="csv_file"
                        class="form-control"
                        @change="loadCSV($event)"
                      />
                    </div>-->
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <label>Gerar amostra de :&nbsp;</label>
                  <md-radio v-model="quantidadeAmostra" value="10"
                    >10 linhas</md-radio
                  >
                  <md-radio v-model="quantidadeAmostra" value="100"
                    >100 linhas</md-radio
                  >
                  <md-radio v-model="quantidadeAmostra" value="1000"
                    >1000 linhas</md-radio
                  >
                </div>
                <!--<div class="md-layout-item md-small-size-100 md-size-100">
                    <md-checkbox v-model="header_row" disabled
                      >Arquivo contem a linha inicial de cabeçalio
                      ?</md-checkbox
                    >
                  </div>-->

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="Tipo do Arquivo"
                    :rules="{
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <label>Tipo de Arquivo&nbsp;:</label>

                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="servidor"
                      >Servidor</md-radio
                    >
                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="pensionista"
                      >Pensionista</md-radio
                    >
                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="quota_parte"
                      >Quota Parte</md-radio
                    >
                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="ficha_financeira"
                      >Ficha Financeira</md-radio
                    >
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="col-xs-2">
                  <label style="padding-left: 20px">&nbsp;</label>
                  <md-button
                    type="submit"
                    class="md-raised md-success"
                    v-show="showButtonSalvar"
                  >
                    Importar CSV
                  </md-button>
                </div>
                <label
                  style="padding-top: 10px; font-weight: 500;"
                  v-show="showButtonSalvar"
                  >&nbsp;Total de linhas lidas: {{ totalLinhas }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="md-layout"
          style="padding: 10px 0px 0px 0px;"
          v-if="showCSV"
        >
          <div class="md-layout-item md-small-size-100 md-size-100 ">
            <table
              v-if="parse_csv"
              class="table-linhas"
              style="background-color: white;"
            >
              <thead>
                <tr class="table-linhas">
                  <th
                    v-for="(key, index) in parse_header"
                    :key="index"
                    @click="sortBy(key)"
                    :class="{ active: sortKey == key }"
                    class="table-linhas"
                  >
                    {{ key | capitalize }}
                    <span
                      class="arrow"
                      :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"
                    >
                    </span>
                  </th>
                </tr>
              </thead>
              <tr
                v-for="(csv, idex) in parse_csv"
                :key="idex"
                class="table-linhas"
              >
                <template v-if="idex < Number(quantidadeAmostra)">
                  <td
                    v-for="(key, idx) in parse_header"
                    :key="idx"
                    nowrap
                    class="table-linhas"
                  >
                    {{ csv[key] }}
                  </td>
                </template>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitZip)">
        <div class="md-layout md-gutter md-alignment-center" v-if="showPDF">
          <div class="md-layout-item">
            <h5
              class="title"
              style="font-weight:350;padding-bottom: 5px;margin-bottom: 5px; margin-top: 5px;"
            >
              Escolha o arquivo Zip com os PDFs para importar e em seguido o
              tipo de importação.
            </h5>
            <md-button
              class="md-dense md-raised md-success"
              @click="importFile('initial')"
            >
              Voltar</md-button
            >&nbsp;&nbsp;
            <md-button
              class="md-dense md-raised md-success"
              @click="importFile('csv')"
              >IMPORTAR CSV</md-button
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <div class="panel panel-sm">
              <div class="panel-heading">
                <h4>Importar PDFs</h4>
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-50"
                style="padding-top: 10px;"
              >
                <ValidationProvider
                  name="Nome da Pasta/Objeto"
                  :rules="{
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <label class="text-left">Digite o nome da Pasta. </label>
                  <md-chips
                    class="md-success chip_border"
                    v-model="nomePasta"
                    :md-limit="1"
                    md-placeholder="Entre com o Nome da Pasta/Objeto"
                  >
                    <label>Nome da Pasta/Objeto</label>
                  </md-chips>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-50"
                style="padding-top: 10px;"
              >
                <ValidationProvider
                  name="Ordem do Nome do Arquivo"
                  :rules="{
                    required: true
                  }"
                  v-slot="{ passed, failed, errors }"
                >
                  <template>
                    <br />
                    <label class="text-left"
                      >Selecione a Ordem da Estrutura do Nome do arquivo.
                    </label>

                    <treeselect
                      v-model="nomeOrdem"
                      :multiple="true"
                      :options="opcoesNomesOrdem"
                    />
                  </template>
                  <span
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-100"
                style="padding-top: 10px;"
              >
                <div class="label-arquivo">
                  <form
                    v-bind:id="2"
                    :key="2"
                    enctype="multipart/form-data"
                    ref="form2"
                  >
                    <label for="zip_file" class="text-left"
                      >Arquivo ZIP para importar
                    </label>

                    <div class="">
                      <input
                        type="file"
                        id="zip_file"
                        name="zip_file"
                        class="form-control"
                        @change="loadZIP($event)"
                        accept=".zip"
                      />
                    </div>
                  </form>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="Tipo do Arquivo"
                    :rules="{
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <label>Tipo de Arquivo&nbsp; :&nbsp;</label>

                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="servidor"
                      >Servidor</md-radio
                    >
                    <md-radio
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                      v-model="tipoImport"
                      value="pensionista"
                      >Pensionista</md-radio
                    >
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="col-xs-2">
                  <label style="padding-left: 20px">&nbsp;</label>
                  <md-button
                    type="submit"
                    class="md-raised md-success"
                    v-show="showButtonSalvar2"
                  >
                    Importar ZIP
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { PricingCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { upload_csv, upload_pdf_zip } from "../js/file-upload.download.service";
import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const QUOTE = '"';
const ESCAPED_QUOTE = '""';

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    tipo: {
      type: String
    }
  },
  components: {
    PricingCard,
    Treeselect
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile
  },
  beforeMount() {
    this.getPapel();
  },
  mounted() {
    if (this.tipo == "csv") {
      this.importFile(this.tipo);
    }
  },
  data() {
    return {
      showCards: true,
      showCSV: false,
      showPDF: false,
      totalLinhas: 0,
      file: null,
      quantidadeAmostra: "10",
      tipoImport: "",
      upload: "",
      header_row: true,
      channel_name: "",
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      sortOrders: {},
      sortKey: "",
      showButtonSalvar: false,
      showButtonSalvar2: false,
      nomePasta: [],
      nomeOrdem: null,
      opcoesNomesOrdem: [
        {
          id: "cpf",
          label: "CPF"
        },
        {
          id: "nome",
          label: "NOME"
        },
        {
          id: "siape",
          label: "SIAPE"
        }
      ]
    };
  },
  filters: {
    capitalize: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    sortBy: function(key) {
      let vm = this;
      vm.sortKey = key;
      vm.sortOrders[key] = vm.sortOrders[key] * -1;
    },
    resetForm(tipo) {
      this.tipoImport = "";
      this.quantidadeAmostra = "10";
      this.nomePasta = [];
      this.nomeOrdem = [];
      if (tipo === "pdf") {
        requestAnimationFrame(() => {
          this.$refs.observer2.reset();
          this.$refs.form2.reset();
        });
      } else if (tipo === "csv") {
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
          this.$refs.form.reset();
        });
      }
    },
    importFile(tipo) {
      if (tipo === "csv") {
        this.resetForm(tipo);
        this.showCSV = true;
        this.showCards = false;
        this.showPDF = false;
      } else if (tipo === "pdf") {
        this.resetForm(tipo);
        this.showPDF = true;
        this.showCards = false;
        this.showCSV = false;
      } else if (tipo === "initial") {
        this.resetForm(tipo);
        this.showPDF = false;
        this.showCards = true;
        this.showCSV = false;
      }
    },
    csvJSON(csv) {
      let vm = this;
      let lines = csv.split("\n");
      let result = [];
      let headers = lines[0].split(";");
      vm.parse_header = lines[0].split(";");
      lines[0].split(";").forEach(function(key) {
        vm.sortOrders[key] = 1;
      });

      Object.keys(vm.parse_header).map(key => {
        let s = vm.parse_header[key];
        s = s.trim();
        if (s.startsWith(QUOTE) && s.endsWith(QUOTE)) {
          s = s.substring(1, s.length - 1);
          if (s.includes(ESCAPED_QUOTE)) {
            s = s.replace(ESCAPED_QUOTE, QUOTE);
          }
        }
        vm.parse_header[key] = s;
        headers[key] = s;
      });

      lines.map(function(line, indexLine) {
        if (indexLine < 1) return; // Jump header line

        let obj = {};
        let currentline = line.split(";");

        headers.map(function(header, indexHeader) {
          if (typeof currentline[indexHeader] !== "undefined") {
            let s = currentline[indexHeader];
            s = s.trim();
            if (s.startsWith(QUOTE) && s.endsWith(QUOTE)) {
              s = s.substring(1, s.length - 1);
              if (s.includes(ESCAPED_QUOTE)) {
                s = s.replace(ESCAPED_QUOTE, QUOTE);
              }
            }
            obj[header] = s;
          } else {
            obj[header] = currentline[indexHeader];
          }
        });
        result.push(obj);
      });
      result.pop(); // remove the last item because undefined values
      this.totalLinhas = result.length;
      return result; // JavaScript object
    },
    loadCSV(e) {
      this.totalLinhas = 0;
      this.showButtonSalvar = true;
      Swal.queue([
        {
          title: "Carregar Arquivo",
          confirmButtonText: "CARREGAR",
          text:
            "Primeiro será carregado o arquivo para teste, após verificação use o botão de Importar !",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch("")
              .then(() => {
                let timerInterval;
                let vm = this;
                if (window.FileReader) {
                  let reader = new FileReader();
                  reader.readAsText(e.target.files[0]);
                  //var zip = new JSZip();
                  //zip.file(e.target.files[0]);
                  this.file = e.target.files[0];
                  //let allZip = zip.file(this.file.name, this.file);
                  //this.promise = zip.generateAsync({ type: "string" });
                  reader.onload = function(event) {
                    let csv = event.target.result;
                    vm.parse_csv = vm.csvJSON(csv);
                  };
                  reader.onerror = function(evt) {
                    if (evt.target.error.name == "NotReadableError") {
                      this.handleError(evt.target.error);
                    }
                  };
                } else {
                  this.handleError(
                    "FileReader não é suportado por este browser."
                  );
                }
              })
              .then(
                Swal.insertQueueStep({
                  title: "Lendo arquivo...",
                  html: "<b></b>",
                  onBeforeOpen: () => {
                    Swal.showLoading();
                  },
                  onOpen: () => {
                    const content = Swal.getContent();
                    this.delay(10000).then(Swal.close());
                  }
                })
              );
          }
        }
      ]);
    },
    loadZIP(e) {
      this.totalLinhas = 0;
      this.showButtonSalvar2 = true;
      Swal.queue([
        {
          title: "Carregar Arquivo",
          confirmButtonText: "CARREGAR",
          text:
            "Primeiro será carregado o arquivo para teste, após verificação use o botão de Importar !",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch("")
              .then(() => {
                let timerInterval;
                let vm = this;
                if (window.FileReader) {
                  let reader = new FileReader();
                  //reader.readAsText(e.target.files[0]);

                  this.file = e.target.files[0];

                  //reader.onerror = function(evt) {
                  //  if (evt.target.error.name == "NotReadableError") {
                  //    this.handleError(evt.target.error);
                  //  }
                  // };
                } else {
                  this.handleError(
                    "FileReader não é suportado por este browser."
                  );
                }
              })
              .then(
                Swal.insertQueueStep({
                  title: "Lendo arquivo...",
                  html: "<b></b>",
                  onBeforeOpen: () => {
                    Swal.showLoading();
                  },
                  onOpen: () => {
                    const content = Swal.getContent();
                    this.delay(10000).then(Swal.close());
                  }
                })
              );
          }
        }
      ]);
    },
    async submitZip() {
      try {
        let papel = this.papel;
        let result = null;
        const formData = new FormData();
        formData.append("files", this.file, this.file.name);
        formData.append("tipoImport", this.tipoImport.toUpperCase());
        formData.append("nomePasta", this.nomePasta);
        formData.append("nomeOrdem", this.nomeOrdem);

        Swal.fire({
          title: "Importando arquivo...",
          html: "<b></b>",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          onOpen: () => {
            //this.save(formData);
            const content = Swal.getContent();
            this.save_zip(formData)
              .then(resp => {
                result = resp;
                if (resp.status !== 200) {
                  Swal.close();
                  throw result;
                } else {
                  let totalImportados = 0;
                  let totalTempoLinha = 0;
                  let totalTempo = 0;
                  this.mensagem = "";
                  this.mensagem = result.data.mensagem;
                  totalTempo = result.data.tempo_decorrido;
                  totalTempoLinha = result.data.tempo_decorrido_por_arquivo;
                  totalImportados = result.data.total_importados;
                  Swal.fire({
                    title: "Resultado",
                    html: `<p style="text-align: center;">${this.mensagem}</p>
                          <table style=\"height: 155px; margin-left: auto; margin-right: auto; width: 335px;\">
                          <tbody>
                          <tr>
                          <td style="width: 195.5px;">&nbsp;</td>
                          <td style="width: 122.5px; text-align: center;">&nbsp;</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Total de Arquivos</td>
                          <td style="width: 122.5px; text-align: center;">${totalImportados}</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Tempo Total</td>
                          <td style="width: 122.5px; text-align: center;">${totalTempo}</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Tempo por Linha</td>
                          <td style="width: 122.5px; text-align: center;">${totalTempoLinha}</td>
                          </tr>
                          </tbody>
                          </table>
                          <p style="text-align: center;">&nbsp;</p>`
                    //onOpen: () => {
                    //const content = Swal.getContent();
                    //}
                  });
                  this.resetForm("pdf");
                }
              })
              .catch(err => {
                //console.log(err);
                this.resetForm("pdf");
                this.handleError(err);
              });
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    async submit() {
      try {
        let papel = this.papel;
        let result = null;
        if (this.file != null) {
          var formData = new FormData();
          formData.append("files", this.file, this.file.name);
          formData.append("tipoImport", this.tipoImport);
          Swal.fire({
            title: "Importando arquivo...",
            html: "<b></b>",
            onBeforeOpen: () => {
              Swal.showLoading();
            },
            onOpen: () => {
              //this.save(formData);
              const content = Swal.getContent();
              this.save_csv(formData)
                .then(resp => {
                  result = resp;
                  if (resp.status !== 200) {
                    Swal.close();
                    throw result;
                  } else {
                    let totalImportados = 0;
                    let totalTempoLinha = 0;
                    let totalTempo = 0;
                    this.mensagem = "";
                    this.mensagem = result.data.mensagem;
                    totalTempo = result.data.tempo_decorrido;
                    totalTempoLinha = result.data.tempo_decorrido_por_linha;
                    totalImportados = result.data.total_importados;
                    Swal.fire({
                      title: "Resultado",
                      html: `<p style="text-align: center;">${this.mensagem}</p>
                          <table style=\"height: 155px; margin-left: auto; margin-right: auto; width: 335px;\">
                          <tbody>
                          <tr>
                          <td style="width: 195.5px;">&nbsp;</td>
                          <td style="width: 122.5px; text-align: center;">&nbsp;</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Total de ${this.tipoImport}</td>
                          <td style="width: 122.5px; text-align: center;">${totalImportados}</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Tempo Total</td>
                          <td style="width: 122.5px; text-align: center;">${totalTempo}</td>
                          </tr>
                          <tr>
                          <td style="width: 195.5px; text-align: left;">Tempo por Linha</td>
                          <td style="width: 122.5px; text-align: center;">${totalTempoLinha}</td>
                          </tr>
                          </tbody>
                          </table>
                          <p style="text-align: center;">&nbsp;</p>`
                      //onOpen: () => {
                      //const content = Swal.getContent();
                      //}
                    });
                    this.resetForm("csv");
                    formData = null;
                    this.parse_csv = [];
                    this.parse_header = [];
                    this.totalLinhas = 0;
                    this.file = null;
                  }
                })
                .catch(err => {
                  //console.log(err);
                  formData = null;
                  this.parse_csv = [];
                  this.parse_header = [];
                  this.totalLinhas = 0;
                  this.file = null;
                  this.resetForm("csv");
                  this.handleError(err);
                });
            }
          });
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async save_csv(formData) {
      // upload data to the server
      return await upload_csv(formData)
        .then(resp => {
          if (resp.data.mensagem.includes("sucesso")) {
            return resp;
          } else {
            return resp;
          }
        })
        .catch(err => {
          return err;
          //this.handleError(err);
        });
    },
    async save_zip(formData) {
      // upload data to the server
      return await upload_pdf_zip(formData)
        .then(resp => {
          if (resp.data.mensagem.includes("sucesso")) {
            return resp;
          } else {
            return resp;
          }
        })
        .catch(err => {
          return err;
          //this.handleError(err);
        });
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    handleError(err) {
      console.log(err);
      //console.log(err.response);
      let title = "Erro!";
      if (err === 500 || err === 404 || err.status === 404) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (
        err === 401 ||
        err.status === 401 ||
        err.data === "Unauthorized"
      ) {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem = err.response.data.mensagem;
          }
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else {
            let resp = err.data;
            title = `<h4><strong>${resp.mensagem}</strong></h4>`;
            this.mensagem = "Erro: " + resp.erro;
          }
        } else if (err.status === 400) {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else {
            let resp = err.data;
            title = `<h3><strong>${resp.mensagem}</strong></h3>`;
            this.mensagem = "Erro: " + resp.erro;
          }
        } else if (err.status === 500) {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else {
            let resp = err.data;
            if (resp.erro && resp.erro.includes("viacep.com.br")) {
              title = `<h3><strong>${resp.mensagem}</strong></h3>`;
              this.mensagem = "Erro: " + resp.erro;
            } else if (err.data) {
              this.mensagem = err.data.mensagem;
            } else {
              this.mensagem = err.data.mensagem;
            }
          }
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: title,
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    goToExportarServidores() {
      window._Vue.$router
        .push({ name: "Exportar Servidores" })
        .catch(err => {});
    }
  }
};
</script>
<style>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

.md-radio .md-radio-label {
  padding-left: 10px;
  color: #3c4858;
  font-weight: 400;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: lowercase;
}

::v-deep .uppercase {
  text-transform: uppercase;
}

.switch-div {
  /*display: inline-block;*/
  float: right;
}

.label-arquivo {
  padding-top: 10px;
}

.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
}
.panel.panel-sm {
  width: 100%;
  background-color: #fff;
}
.panel-heading {
  width: 100%;
  border-bottom: 2px solid #dfdfdf;
  background-color: #4caf50;
  color: white;
  font-weight: 500;
}
.panel-heading h1,
.panel-heading h2,
.panel-heading h3,
.panel-heading h4,
.panel-heading h5,
.panel-heading h6 {
  margin: 0;
  padding: 5px 10px;
}
.panel-body {
  padding: 15px 20px;
  width: 100%;
}
.table-linhas table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  background-color: white;
}

.table-linhas td,
.table-linhas th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: 11px;
  padding: 5px;
}

.table-linhas tr:nth-child(even) {
  background-color: #dddddd;
}

.form-control,
.form-group {
  display: block;
  width: 50%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.md-checkbox.md-disabled .md-checkbox-label,
.md-checkbox.md-disabled .md-checkbox-container {
  opacity: 0.9;
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.md-checkbox.md-checked.md-disabled .md-checkbox-container {
  opacity: 0.5;
}

.vue-treeselect__multi-value-item {
  background: #62dc67;
  color: #000000;
  font-weight: 500;
}

.vue-treeselect__value-remove {
  color: #000000;
}

.chip_border {
  border-bottom: 1px solid #000000;
}
</style>
