import * as axios from "axios";
import store from "../../../store";
import BASE_URL from "@/properties.js";

export const instance = axios.create({
  baseURL: BASE_URL
});

const token = localStorage.getItem("token");
instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function downloadExport(axios, papel, body) {
  if (papel === "ADMIN") {
    axios({
      url: `api/v1/admin/ficha_financeira/export`,
      method: "POST",
      data: body,
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        let ts = new Date();
        let timestamp = ts.toISOString().split(".")[0];
        timestamp = timestamp.replace(":", "_");
        link.setAttribute(
          "download",
          "export_ficha_financeira-" + timestamp + ".csv"
        );
      }
      document.body.appendChild(link);
      link.click();
    });
  }
}

export { downloadExport };
